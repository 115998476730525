import React from 'react';
import '../styles/Contact.css'; // Import your CSS file for styling
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function Contact() {
  return (
    <section id="contact" className="contact py-5 bg-dark text-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6">
            <div className="contact-form bg-light text-dark p-4 rounded shadow">
              <h2 className="text-center mb-4">Contact Us</h2>
              <form action="https://formspree.io/f/myyqppow" method="POST">
                <div className="mb-3">
                  <input type="text" name="name" placeholder="Your Name" className="form-control" />
                </div>
                <div className="mb-3">
                  <input type="email" name="_replyto" placeholder="Your Email" className="form-control" />
                </div>
                <div className="mb-3">
                  <textarea name="message" rows="4" placeholder="Your Message" className="form-control"></textarea>
                </div>
                <button type="submit" className="btn btn-primary w-100">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
