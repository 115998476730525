
import React from 'react';
import '../styles/Marketing.css'; // Import your custom CSS file
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function Programming() {
  return (
    <div className="advertising-container">
      <div className="advertising-header">
      <h2>Programming Services</h2>
      </div>
      <div className="advertising-content">
      <p>We provide expert programming and software development services tailored to your business.</p>
      </div>
    </div>
  );
}

export default Programming;
