import React from 'react';
import missionVideo from '../videos/mission.mp4'; // Import your mission video
import '../styles/AboutUs.css'; // Import the CSS file
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function AboutUs() {
  return (
    <div className="about-us container mt-4">
      <div className="row justify-content-center">
        <div className="about-content col-lg-8 col-md-10 text-center">
          <h1 className="text-white mb-3">Mission</h1>
          <p className="text-white bg-secondary p-3 rounded mb-4">
            At Grow Up, our mission is to transform creative ideas into exceptional digital experiences. We strive to empower businesses and individuals through innovative marketing, cutting-edge programming, captivating filming, exquisite design, and captivating animation. Our purpose is to help our clients grow, thrive, and stand out in an ever-evolving digital landscape. We are committed to excellence, creativity, and unwavering dedication to our clients' success.
          </p>
          <h1 className="text-white mb-3">Vision</h1>
          <p className="text-white bg-secondary p-3 rounded mb-4">
            Our vision at Grow Up is to be a recognized leader in the realms of marketing, programming, filming, design, and animation. We aim to be the go-to agency that sets industry standards for creativity and innovation. We aspire to continuously push boundaries, creating extraordinary digital content and solutions that inspire and engage. We envision a future where our clients achieve their dreams, and together, we foster a world where every idea can 'Grow Up' into something extraordinary.
          </p>
        </div>
      </div>

      <div className="row justify-content-center mt-4">
        <div className="video-container col-lg-8 col-md-10">
          <h2 className="text-white mb-3">Video</h2>
          <video className="w-100" controls  src={missionVideo}>
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
