// src/firebaseHelpers.js

import { db, storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs } from 'firebase/firestore';


export const uploadVideo = async (file) => {
  const storageRef = ref(storage, `videos/${file.name}`);
  const snapshot = await uploadBytes(storageRef, file);
  return getDownloadURL(snapshot.ref);
};

export const saveVideoUrlToFirestore = async (url) => {
  try {
    const docRef = await addDoc(collection(db, 'videos'), {
      url: url,
      createdAt: new Date()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error adding document to Firestore: ', error);
    throw error;
  }
};

export const fetchPortfolioVideos = async () => {
  try {
    const videoCollection = collection(db, 'portfolioVideos'); // Adjust 'portfolioVideos' to your specific collection name
    const querySnapshot = await getDocs(videoCollection);
    return querySnapshot.docs.map(doc => doc.data().url); // Assuming URLs are stored in the 'url' field
  } catch (error) {
    console.error("Error fetching videos: ", error);
    return [];
  }
};

export const uploadPortfolioVideo = async (file) => {
  try {
    // Upload to Storage
    const storageRef = ref(storage, `portfolio/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(snapshot.ref);

    // Save URL to Firestore
    const docRef = await addDoc(collection(db, 'portfolioVideos'), {
      url: url,
      uploadedAt: new Date()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error uploading video:', error);
    throw error;
  }
};

// those to {uploadPortfolioImage ,fetchPortfolioImages } to upload thumbnail for the videos in the portfolio

// export const uploadPortfolioImage = async (file) => {
//   try {
//     // Upload to Storage
//     const storageRef = ref(storage, `portfolio/images/${file.name}`);
//     const snapshot = await uploadBytes(storageRef, file);
//     const url = await getDownloadURL(snapshot.ref);

//     // Save URL to Firestore
//     const docRef = await addDoc(collection(db, 'portfolioImages'), {
//       url: url,
//       uploadedAt: new Date()
//     });
//     return docRef.id;
//   } catch (error) {
//     console.error('Error uploading image:', error);
//     throw error;
//   }
// };

// export const fetchPortfolioImages = async () => {
//   try {
//     const imageCollection = collection(db, 'portfolioImages'); // Adjust 'portfolioImages' to your collection name
//     const querySnapshot = await getDocs(imageCollection);
//     return querySnapshot.docs.map(doc => doc.data().url); // Assuming URLs are stored in the 'url' field
//   } catch (error) {
//     console.error("Error fetching images: ", error);
//     return [];
//   }
// };

export const fetchClientLogos = async () => {
  try {
    const logosCollection = collection(db, 'clientLogos'); // Adjust 'clientLogos' to your specific collection name
    const querySnapshot = await getDocs(logosCollection);
    return querySnapshot.docs.map(doc => doc.data().url); // Assuming URLs are stored in the 'url' field
  } catch (error) {
    console.error("Error fetching client logos: ", error);
    return [];
  }
};

export const uploadClientLogo = async (file) => {
  try {
    const storageRef = ref(storage, `clientLogos/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(snapshot.ref);

    const docRef = await addDoc(collection(db, 'clientLogos'), {
      url: url,
      uploadedAt: new Date()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error uploading client logo:', error);
    throw error;
  }
};