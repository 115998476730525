// src/components/Clients.js

import React, { useEffect, useState } from 'react';
import { fetchClientLogos } from '../firebaseHelpers'; // Adjust the path as necessary
import '../styles/Clients.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Clients() {
  const [clientLogos, setClientLogos] = useState([]);

  useEffect(() => {
    const loadClientLogos = async () => {
      const logos = await fetchClientLogos();
      setClientLogos(logos);
    };

    loadClientLogos();
  }, []);

  return (
    <section className="clients container mt-4">
      <h2 className="text-center mb-4">Our Partners</h2>
      <div className="row">
        {clientLogos.map((logo, index) => (
          <div key={index} className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
            <img src={logo} alt={`Client ${index + 1}`} className="img-fluid" />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Clients;
