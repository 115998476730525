// Services.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Services.css'; // Import your CSS file for styling
import brand from '../images/brand.svg';
import crm from '../images/crm.svg';
import filming from '../images/filming.svg';
import market from '../images/market.svg';
import seo from '../images/seo.svg';
import social from '../images/social.svg';
import web from '../images/web.svg';
import ppc from '../images/ppc.svg';

function Services() {
  const servicesData = [
    {
      title: 'Market Research',
      description: 'market research to analyze industry trends, customer behaviors, and competitors. This data helps businesses make informed decisions.',
      image: market, // Replace with the path to your image
    },
    {
      title: 'Search Engine Optimization (SEO)',
      description: 'Optimizing websites to rank higher in search engine results pages (SERPs)',
      image: seo, // Replace with the path to your image
    },
    {
      title: 'Pay-Per-Click (PPC) Advertising',
      description: 'Managing paid advertising campaigns on platforms like social media and Google Ads.',
      image: ppc, // Replace with the path to your image
    },
    {
      title: 'Social Media Marketing',
      description: 'Managing social media accounts and running ad campaigns on platforms like Facebook, Instagram, Twitter, and LinkedIn.',
      image: social, // Replace with the path to your image
    },
    {
      title: 'Branding and Creative Services',
      description: 'This includes services related to brand development, design, and creative content.',
      image: brand , // Replace with the path to your image
    },
    {
      title: 'Customer Relationship Management (CRM)',
      description: ' Implementing CRM systems and strategies to manage customer interactions and improve retention.',
      image: crm, // Replace with the path to your image
    },
    {
      title: 'Web Development',
      description: 'Building and maintaining websites that are visually appealing, user-friendly, and optimized for search engines.',
      image: web, // Replace with the path to your image
    },
    {
      title: 'Filming and Cinematography',
      description: 'Utilizing professional equipment and expertise to capture high-quality video footage, including on-location shoots, interviews, and b-roll footage.',
      image: filming, // Replace with the path to your image
    },
    // Add more services as needed
  ];
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Unobserve after triggering animation
        }
      });
    }, options);

    const servicesSection = document.getElementById('services');
    if (servicesSection) {
      observer.observe(servicesSection);
    }

    return () => {
      if (servicesSection) {
        observer.unobserve(servicesSection); // Clean up when component unmounts
      }
    };
  }, []);

  return (
    <Container fluid id="services" className={`services ${isVisible ? 'animated' : ''}`}>
      <h2 className="text-center mt-4">OUR SERVICES</h2>
      <Row className="justify-content-center">
        {servicesData.map((service, index) => (
          <Col key={index} md={6} lg={4} className="mb-4">
            <div className="service text-center">
              <img src={service.image} alt={service.title} className="mb-3" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Services;
