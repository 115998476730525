// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAe9mXPvAJ_QiB2gtkLud7juxI0PsW7yNI",
  authDomain: "growup-ca331.firebaseapp.com",
  projectId: "growup-ca331",
  storageBucket: "growup-ca331.appspot.com",
  messagingSenderId: "526614728136",
  appId: "1:526614728136:web:e758056b37c2f8787744fd"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
// Initialize Firebase Authentication and get a reference to the service
// export {auth, db, storage}
export {db, storage}