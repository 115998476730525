
import React, { useState } from 'react';
import { storage, db } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection } from 'firebase/firestore';
import '../styles/AddJob.css';


const AddJob = () => {
  const [jobName, setJobName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState(''); // To display success or error message

  var time = new Date();

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleAddProduct = async () => {
    if (!jobName || !description || !image) {
      setMessage('Please fill in all fields and select an image');
      return;
    }

    const storageRef = ref(storage, `jobImages/${image.name}+${time}`);
    await uploadBytes(storageRef, image);
    const imageUrl = await getDownloadURL(storageRef);

    const jobData = {
      jobName,
      description,
      imageUrl,
    };

    try {
      await addDoc(collection(db, 'jobs'), jobData);
      setMessage('Product added successfully');
    } catch (error) {
      console.error('Error adding product: ', error);
      setMessage('Unable to add the product. There was an issue.');
    }

    setJobName('');
    setDescription('');
    setImage(null);
    time = new Date();
  };

  return (
    <div className="add-product-container">
      <h2>Add Job</h2>
      <input
        type="text"
        placeholder="Product Name"
        value={jobName}
        onChange={(e) => setJobName(e.target.value)}
      />
      <textarea
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      <button onClick={handleAddProduct}>Add Job</button>
      {message && <p className={message.includes('successfully') ? 'success' : 'error'}>{message}</p>}
    </div>
  );
};

export default AddJob;

