import React, { useEffect, useRef, useState } from 'react';
import { fetchPortfolioVideos } from '../firebaseHelpers';
import '../styles/Portfolio.css';
import image1 from '../images/Sara.png.webp';
import image2 from '../images/abed.png.webp';
import image3 from '../images/lastoira.png.webp';

const Portfolio = () => {
  const videoRefs = useRef([]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const loadVideos = async () => {
      const fetchedVideos = await fetchPortfolioVideos();
      setVideos(fetchedVideos);
    };

    loadVideos();
  }, []);

  const handleVideoInteraction = (index, action) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      if (action === 'hover' && videoElement.readyState >= 3) {
        videoElement.play();
      } else if (action === 'click') {
        if (videoElement.paused) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      } else {
        videoElement.pause();
      }
    }
  };

  const getPosterImage = (index) => {
    switch (index) {
      case 0:
        return image1;
      case 1:
        return image2;
      case 2:
        return image3;
      default:
        return null; // Default poster or null
    }
  };

  return (
    <div className="portfolio">
      <div className="text-section">
        <h1>CREATIVE THAT SELLS</h1>
        <p>
          Our goal is to maximize the performance campaigns
          with creative that transcends modern art.
          If you’re looking to take your brand to the next level –
          create an experience for your customers,
          and make potential clients stop scrolling their feed –
          creative production of the highest level is the solution.
        </p>
      </div>
      <div className="video-section">
        <div className="video-row">
          {videos.slice(0, 2).map((videoUrl, index) => (
            <div className="video-container" key={index}>
              <video
                ref={(ref) => (videoRefs.current[index] = ref)}
                className="video"
                loop
                preload="auto"
                muted
                playsInline
                onMouseEnter={() => handleVideoInteraction(index, 'hover')}
                onMouseLeave={() => handleVideoInteraction(index, 'leave')}
                onClick={() => handleVideoInteraction(index, 'click')}
                poster={getPosterImage(index)}
              >
                <source src={videoUrl} type="video/mp4" />
              </video>
            </div>
          ))}
        </div>
        <div className="video-row">
          {videos.slice(2, 3).map((videoUrl, index) => (
            <div className="video-container" key={index}>
              <video
                ref={(ref) => (videoRefs.current[index + 2] = ref)}
                className="video"
                loop
                preload="auto"
                muted
                playsInline
                onMouseEnter={() => handleVideoInteraction(index + 2, 'hover')}
                onMouseLeave={() => handleVideoInteraction(index + 2, 'leave')}
                onClick={() => handleVideoInteraction(index + 2, 'click')}
                poster={getPosterImage(index + 2)}
              >
                <source src={videoUrl} type="video/mp4" />
              </video>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
