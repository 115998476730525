import React from 'react';
import { useSpring, animated } from 'react-spring';
import Services from './Services'; // Import your Services component
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const AnimatedServices = () => {
  const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.div style={fadeIn} className="container mt-4">
      <div className="row">
        <div className="col-12">
          <Services />
        </div>
      </div>
    </animated.div>
  );
};

export default AnimatedServices;
  