import React from 'react';
import '../styles/About.css';
import { Link } from 'react-router-dom';
import video from '../videos/growupintro2.mov';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function About() {
  return (
    <section id="about" className="about container-fluid">
      <div className="row">
        <div className="about-video col-md-6">
          <h1>About Grow Up</h1>
          <video autoPlay muted loop>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="about-text col-md-6">
          <h1>About Grow Up</h1>
          <p>We are a Digital Marketing Agency combining the power of Performance and Creative to scale your business.</p>
        <p>
          Unlike a human brain, a seamless cooperation between calculated analytics and passionate creativity is required to achieve extraordinary results.
        </p>
        
        <p>
        <Link
            to="/aboutus"// Use the ID of the header section
            className="see-more"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
             <p className='see'>See</p> <p className='more'> More</p> <span className="arrows">&#8594;</span>
          </Link>
        </p>
        {/* Add more text content as needed */}
        </div>
      </div>
    </section>
  );
}

export default About;
