import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { animateScroll as scroll } from 'react-scroll';
import { Link as ScrollLink } from 'react-scroll'; // Import ScrollLink from react-scroll
import '../styles/NavBar.css';
import logo from '../images/growup2.png';
import navbarIcon from '../images/menu.svg';

function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="nav">
      <div className="nav-logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className={`nav-list ${menuOpen ? 'open' : ''}`}>
      <div className="nav-item">
          <Link
            to="/" // Use the ID of the header section
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Home
          </Link>
        </div>
        {/* <div className="nav-item">
          <Link
            to="/social-media" // Use the ID of the header section
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Social Media
          </Link>
        </div> */}
        <div className="nav-item dropdown">
          <span className="nav-item-text" onClick={toggleMenu}>
            Services
            <span className="dropdown-icon">&#9662;</span>
          </span>
          <div className="dropdown-content">
            <Link to="/marketing">Marketing</Link>
            <Link to="/advertising">Advertising</Link>
            <Link to="/programming">Programming</Link>
            <Link to="/filming">Filming</Link>
          </div>
        </div>
        <div className="nav-item">
          <ScrollLink
            to="portfolio"
            spy={true}
            smooth={true}
            offset={-70} // Adjust the offset as needed to account for fixed header height
            duration={500}
          >
            Portfolio
          </ScrollLink>
        </div>
        <div className="nav-item">
          <ScrollLink
            to="about" // Use the ID of the About section
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            About
          </ScrollLink>
        </div>
        <div className="nav-item">
          <ScrollLink
            to="contact" // Use the ID of the About section
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Contact Us
          </ScrollLink>
        </div>
      </div>
      <div className={`nav-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <img src={navbarIcon} alt="Menu Icon" />
      </div>
    </nav>
  );
}

export default NavBar;
