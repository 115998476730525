
import React from 'react';
import '../styles/Marketing.css'; // Import your custom CSS file
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function Marketing() {
  return (
    <div className="advertising-container">
      <div className="advertising-header">
        <h2>Marketing Services</h2>
      </div>
      <div className="advertising-content">
        <p>        We offer comprehensive marketing strategies to help your business reach its target audience effectively.</p>
      </div>
    </div>
  );
}

export default Marketing;
