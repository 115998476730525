import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/YourGrowth.css'; // Import your CSS file for styling

function YourGrowth() {
  const growthItems = [
    {
      title: 'STRATEGY',
      description: (
        <span>
          <h4>Macroeconomic Analysis.</h4>
          <h4>Competitive Analysis</h4>
          <h4>Advanced SWOT</h4>
          <h4>Customer Persona Analysis</h4>
          <h4>Building a Marketing Funnel</h4>
        </span>
      ),
    },
    {
      title: 'PERFORMANCE',
      description: (
        <span>
          <h4> Setting ROAS-oriented KPIs</h4>
          <h4>Track potential customers</h4>
          <h4>Event Profiling</h4>
          <h4>Marketing Automation</h4>
          <h4>Multiplatform Management</h4>
        </span>
      ),
    },
    {
      title: 'CREATIVE',
      description: (
        <span>
          <h4> Brand Building</h4>
          <h4>Production and Design</h4>
          <h4>Content Creation</h4>
          <h4>Social Media Management</h4>
          <h4>Advertising Screens</h4>
        </span>
      ),
    },
  ];
  
  

  return (
    <section className="your-growth bg-dark text-white py-5" id="your-growth">
      <Container>
        <h2 className="text-center mb-4"><span className="text-primary">Your</span> <span className="text-warning">Growth</span></h2>
        <Row>
          {growthItems.map((item, index) => (
            <Col key={index} xs={12} sm={6} md={6} lg={4} className="mb-4">
              <div className="growth-box bg-secondary p-3 border-radius">
                <h1 className="text-warning">{item.title}</h1>
                <div>{item.description}</div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default YourGrowth;
