// src/Header.js

import React, { useEffect, useState } from 'react';
import { db } from '../firebase'; // Adjust the path as necessary
import { collection, getDocs } from 'firebase/firestore';

function Header() {
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const videoCollection = collection(db, 'videos'); // Adjust 'videos' to your specific collection name
        const querySnapshot = await getDocs(videoCollection);
        const videosData = querySnapshot.docs.map(doc => doc.data());

        if (videosData.length > 0) {
          setVideoUrl(videosData[0].url); // Assuming the URL is in the 'url' field
        }
      } catch (error) {
        console.error("Error fetching video: ", error);
      }
    };

    fetchVideoUrl();
  }, []);

  // Log the video URL to the console for debugging
  useEffect(() => {
    if (videoUrl) {
      console.log("Video URL: ", videoUrl);
    }
  }, [videoUrl]);

  return (
    <div style={{ position: 'relative', width: '100vw', overflow: 'hidden', paddingTop: '120px' }}>
      {videoUrl ? (
        <video 
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          autoPlay
          muted
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <p>Loading video...</p> // Display a loading message or spinner
      )}
    </div>
  );
}

export default Header;
