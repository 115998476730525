import React from 'react';
import video from '../videos/mission.mp4';
import '../styles/Filming.css'; // Ensure this path is correct for your CSS file
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Filming = () => {
  return (
    <div className="filming-page bg-light">
      <header className="page-header bg-dark text-white text-center py-4">
        <div className="container">
          <h1>Filming Portfolio</h1>
          <p>Welcome to our filming portfolio. Check out our latest work.</p>
        </div>
      </header>
      <section className="video-gallery py-4">
        <div className="container">
          <div className="row">
            {/* Video 1 */}
            <div className="col-md-6 mb-4">
              <div className="video-container">
                <video controls className="w-100">
                  <source src={video} type="video/mp4" />
                </video>
              </div>
            </div>

            {/* Video 2 */}
            <div className="col-md-6 mb-4">
              <div className="video-container">
                <video controls className="w-100">
                  <source src={video} type="video/mp4" />
                </video>
              </div>
            </div>

            {/* Repeat similar structure for each video */}
            {/* Add more videos here */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Filming;
