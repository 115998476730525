import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/App.css';
import Home from './components/Home';
import Marketing from './components/Marketing';
import Advertising from './components/Advertising';
import Programming from './components/Programming';
import Filming from './components/Filming';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import AboutUs from './components/AboutUs';
import AddJobForm from './components/AddJobForm';
import JobList from './components/JobListings';
import SocialMedia from './components/SocialMedia';



function App() {
  return (
    <Router>
      <div className="app">
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/marketing" element={<Marketing />} />
          <Route path="/advertising" element={<Advertising />} />
          <Route path="/programming" element={<Programming />} />
          <Route path="/filming" element={<Filming />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/growupnews" element={<JobList />} />
          <Route path="/addjob" element={<AddJobForm />} />
          <Route path="/social-media" element={<SocialMedia />} />
          {/* Other routes */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
