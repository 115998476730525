import React from 'react';
import '../styles/HeaderCaption.css'; // Ensure the path is correct
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function HeaderCaption() {
  return (
    <div className="header-caption text-center bg-dark text-white py-4">
      <div className="container">
        <h1 className="display-4">We Grow Up Your Business</h1>
        <p className="lead">
          Whether you're a startup, small business, or a well-known corporation, GrowUp is here to help you thrive digitally in the industry. Let us grow, thrive, and make your mark come true.
        </p>
      </div>
    </div>
  );
}

export default HeaderCaption;
