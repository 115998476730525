import React from 'react';
import '../styles/Advertising.css'; // Make sure to create this CSS file

function Advertising() {
  return (
    <div className="advertising-container">
      <div className="advertising-header">
        <h2>Advertising Services</h2>
      </div>
      <div className="advertising-content">
        <p>We specialize in creating impactful advertising campaigns that grab attention and drive results.</p>
      </div>
    </div>
  );
}

export default Advertising;
