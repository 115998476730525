import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css'; // Ensure this path is correct for your CSS file
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';

function Footer() {
  return (
    <footer className="footer bg-dark text-white py-3">
      <div className="container text-center text-md-start">
        <div className="row align-items-center">
          <div className="col-md-6 mb-3 mb-md-0">
            <p className="mb-0">&copy; {new Date().getFullYear()} GrowUp. All rights reserved.</p>
          </div>
          <div className="col-md-6">
            <div className="social-icons d-flex justify-content-md-end justify-content-center">
              <a href="https://www.facebook.com/growup.ps.agency/" target="_blank" rel="noopener noreferrer" className="text-white me-2">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href="https://www.instagram.com/growup_agencyps/" target="_blank" rel="noopener noreferrer" className="text-white me-2">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://www.linkedin.com/company/99926699/admin/feed/posts/" target="_blank" rel="noopener noreferrer" className="text-white me-2">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <Link to="/growupnews" className="text-white">
                <FontAwesomeIcon icon={faNewspaper} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
