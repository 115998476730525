
import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import '../styles/JobListing.css';
import '../styles/Contact.css';

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  
  
  useEffect(() => {
    // Fetch product data from Firestore
    const fetchJobs = async () => {
      const jobsCollection = collection(db, "jobs");
      const querySnapshot = await getDocs(jobsCollection);
      const jobsData = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setJobs(jobsData);

    };
    fetchJobs();
  });
  
  
  return (
    <div className="product-list-container">
      {jobs.map((job) => (
        <div className="product-card" key={job.id}>
          <h1>{job.jobName}</h1>
          <p>{job.description}</p>
          <img src={job.imageUrl} alt={job.jobName} />

          <section id="contact" className="contact">
  <div className="contact-form">
    <h2>Contact Us</h2>
    <form action="https://formspree.io/f/myyqppow" method="POST">
      <input type="text" name="name" placeholder="Your Name" />
      <input type="email" name="_replyto" placeholder="Your Email" />
      <textarea name="message" rows="4" placeholder="Your Message"></textarea>
      <label for="cv">Upload your CV:</label>
      <input type="file" name="cv" accept=".pdf, .doc, .docx" />
      <button type="submit">Send Message</button>
    </form>
  </div>
</section>
        </div>
      ))}
    </div>
  );
};

export default JobList;





