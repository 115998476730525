import React from 'react';
import AnimatedServices from './AnimatedServices';
import Portfolio from './Portfolio';
import Clients from './Clients';
import HeaderCaption from './HeaderCaption';
import About from './About'; // Import the About component
import Contact from './Contact'; // Import the Contact component
import YourGrowth from './YourGrowth'; // Import the new component
import Header from './Header';



function Home() {
  return (
    <div>
        <Header />
        <HeaderCaption />
        <YourGrowth />
        <AnimatedServices />
        <Portfolio />
        <Clients />
        <About />
        <Contact />
    </div>
  );
}

export default Home;
