import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaFacebook, FaWhatsapp, FaInstagram, FaLinkedin, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import logo from '../images/growup2-2.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/SocialMedia.css';

const textStyle = {
  color: '#29ABE2',
  fontFamily: '"STC", sans-serif',
  fontSize: '32.81pt',
};

const socialMediaStyle = {
  background: 'linear-gradient(to bottom right, #1A9CD9 7%, #ffffff 50%, #FFDF00 100%)',
  // ... any other styles you need
};

const SocialMedia = () => {
  return (
    <Container className="social-media" style={socialMediaStyle}>
      <Row className="justify-content-center align-items-center">
        <Col xs="12" className="text-center">
          <img src={logo} alt="Grow Up Logo" className="img-fluid" />
          <p style={textStyle} className='textStyle'>We Do it Better</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto">
          <a href="https://www.facebook.com/growup.ps.agency" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="social-icon" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="https://wa.me/972594601456" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className="social-icon" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="https://www.instagram.com/growup_agencyps/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="social-icon" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="https://www.linkedin.com/company/99926699/admin/feed/posts/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="social-icon" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="tel:+972594244447" target="_blank" rel="noopener noreferrer">
            <FaPhone className="social-icon" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="https://maps.google.com/?q=your-location" target="_blank" rel="noopener noreferrer">
            <FaMapMarkerAlt className="social-icon" />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default SocialMedia;
